import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'magicaLab';
  loading: boolean = true;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, pos - 50);
          } else {
              window.clearInterval(scrollToTop);
          }
        }, 8);
    });

    let ldTimer = setTimeout(() => {
      this.loading = false;
      clearInterval(ldTimer);
    }, 2000);
  }
}
