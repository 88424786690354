import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: "",
    loadChildren: ()=>import("./landing/landing.module").then(m=>m.LandingModule)
  },
  
  {
    path: "",
    loadChildren: ()=>import("./blog/blog.module").then(m=>m.BlogModule)
  },
  
  {
    path: "",
    loadChildren: ()=>import("./work/work.module").then(m=>m.WorkModule)
  },
  
  {
    path: "",
    loadChildren: ()=>import("./contact/contact.module").then(m=>m.ContactModule)
  },
 
  {
    path: "",
    loadChildren: ()=>import("./static/static.module").then(m=>m.StaticModule)
  },
 
  {
    path: "",
    loadChildren: ()=>import("./service/service.module").then(m=>m.ServiceModule)
  },
 // bareilly
 {
  path: "bareilly",
  loadChildren: ()=>import("./bareilly/landing/landing.module").then(m=>m.LandingModule)
},
 {
  path: "bareilly",
  loadChildren: ()=>import("./bareilly/blog/blog.module").then(m=>m.BlogModule)
},
{
    path: "bareilly",
    loadChildren: ()=>import("./bareilly/work/work.module").then(m=>m.WorkModule)
  },
  {
    path: "bareilly",
    loadChildren: ()=>import("./bareilly/static/static.module").then(m=>m.StaticModule)
  },
  {
    path: "bareilly",
    loadChildren: ()=>import("./bareilly/contact/contact.module").then(m=>m.ContactModule)
  },
  {
    path: "bareilly",
    loadChildren: ()=>import("./bareilly/service/service.module").then(m=>m.ServiceModule)
  },

  //Kolkata
  {
    path: "kolkata",
    loadChildren: ()=>import("./kolkata/landing/landing.module").then(m=>m.LandingModule)
  },
   {
    path: "kolkata",
    loadChildren: ()=>import("./kolkata/blog/blog.module").then(m=>m.BlogModule)
  },
  {
      path: "kolkata",
      loadChildren: ()=>import("./kolkata/work/work.module").then(m=>m.WorkModule)
    },
    {
      path: "kolkata",
      loadChildren: ()=>import("./kolkata/static/static.module").then(m=>m.StaticModule)
    },
    {
      path: "kolkata",
      loadChildren: ()=>import("./kolkata/contact/contact.module").then(m=>m.ContactModule)
    },
    {
      path: "kolkata",
      loadChildren: ()=>import("./kolkata/service/service.module").then(m=>m.ServiceModule)
    },

  //admin
  {
    path: "admin",
    loadChildren: () => import("./admin/home/home.module").then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/blog/blog.module").then(m => m.BlogModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/profile/profile.module").then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/work/work.module").then(m => m.WorkModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/testimonials/testimonials.module").then(m => m.TestimonialsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: ()=>import("./admin/contact/contact.module").then(m=>m.ContactModule)
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/upload/upload.module").then(m => m.UploadModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
